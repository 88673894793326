import './style.scss';

import { APP_URL } from '../../../utils/utility';
import { Button } from '@bcmi-labs/react-components';
import { Helmet } from 'react-helmet';
import Layout from '../../../components/layout';
import React from 'react';
import SEO from '../../../components/seo';
import unsubscribe from '../../../assets/Unsubscribe.png';
import { useLocation } from '@reach/router';

const Unsubscribe = () => {
  const title = 'Unsubscribed';

  const search = useLocation().search;
  const isEmail = Boolean(new URLSearchParams(search).get('email'));
  if (!isEmail && typeof window !== 'undefined') {
    window.location.href = APP_URL;
  }

  return (
    <Layout currentLocale="en">
      <Helmet
        bodyAttributes={{
          class: 'newsletter-page-body',
        }}
      />
      <SEO title={title} />
      <main className="newsletter-page page">
        <section>
          <div className="page-content">
            <img className="newsletter-page__image" src={unsubscribe} alt="Unsubscribe page" />
            <div className="newsletter-page__title">Successfully Unsubscribed</div>
            <div className="newsletter-page__subtitle">
              You have been removed from our Newsletter list. We're sorry to see you go. If this was a mistake, you can
              re-subscribe from the footer.
            </div>
            <Button
              variant="primary"
              onPress={() => (typeof window !== 'undefined' ? window.location.replace(APP_URL) : null)}
            >
              TRY AGAIN
            </Button>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Unsubscribe;
